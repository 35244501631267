//y230522 modify variable
// import { constantRoutes } from '@/router'
import { constantRoutes } from '@/router'
import {getRouters} from '@/api/menu'
import Layout from '@/layout/index'
//y230522
// import ParentView from '@/components/ParentView';
import ParentView from '@/components/components/ParentView';
import {toCamelCase} from "@/utils";

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [], // 左侧边菜单的路由，被 Sidebar/index.vue 使用
    topbarRouters: [], // 顶部菜单的路由，被 TopNav/index.vue 使用
    //y230602 增加我的路由字段的定义
        myDefaultRoutes: [{aa:43,bb:22}],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
          state.addRoutes = routes
                  //y230626
    //   state.routes = constantRoutes.concat(routes)
          state.routes = concatConstantAndDynamicRouter(constantRoutes, routes)
    },
      SET_DEFAULT_ROUTES: (state, routes) => {
                //y230626
    //   state.defaultRoutes = constantRoutes.concat(routes)
          state.defaultRoutes = concatConstantAndDynamicRouter(constantRoutes, routes)
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes
    },
      SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes
      },
    //y230602 增加路由数据，独立进行测试
      SET_MYDEFAULT_ROUTES: (state, routes) => {
        //y230626
        // state.myDefaultRoutes = constantRoutes.concat(routes)
        state.myDefaultRoutes = concatConstantAndDynamicRouter(constantRoutes,routes)
    },  
  },
  actions: {
    // 生成路由
    GenerateRoutes({commit}) {
      return new Promise(resolve => {
        // 向后端请求路由数据（菜单）
          getRouters().then(res => {
        
            console.log(res.data, "origin_route")

          const sdata = JSON.parse(JSON.stringify(res.data)) // 【重要】用于菜单中的数据
          const rdata = JSON.parse(JSON.stringify(res.data)) // 用于最后添加到 Router 中的数据
          const sidebarRoutes = filterAsyncRouter(sdata)
          const rewriteRoutes = filterAsyncRouter(rdata, false, true)
              
          rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
          
          commit('SET_ROUTES', rewriteRoutes)
          //y230624  SET_SIDEBAR_ROUTERS 就是定义左侧侧边栏的菜单，后续会到Sidebar/index.vue文件中进行渲染 y240315
          //commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes))
          commit('SET_SIDEBAR_ROUTERS', concatConstantAndDynamicRouter(constantRoutes, sidebarRoutes))
              
          commit('SET_DEFAULT_ROUTES', sidebarRoutes)
          commit('SET_TOPBAR_ROUTES', sidebarRoutes)
          //y230602
          commit('SET_MYDEFAULT_ROUTES', sidebarRoutes)
          resolve(rewriteRoutes)
        })
      })
    }
  }
}

//y230624 
//将常态路由和动态路由进行合并
//在常态路由中，删除不通用的路由，加入动态路由
function concatConstantAndDynamicRouter(constantRoute, dynamicRoute) {

    let filterRoute = constantRoute;
    let hideRoute = ['mesh', 'statistics', 'messages'];

    //删除不通用的路由
    let mergeRoute = filterRoute.filter(route => { 
        if ( route && hideRoute.includes(route.name) ){
            return false;
        } else { 
            return true;
        }
    })
    //合并数组
    mergeRoute = mergeRoute.concat(dynamicRoute);
    return mergeRoute;
}

//y230601  参数分析
// 三个参数分别为：后台返回的路由数据，是否是侧边栏菜单，是否是顶部菜单
// type表示是否过滤子路由的路径，true时过滤component对应子路由的url路径, false时不过滤子路由的路径
// 遍历后台传来的路由字符串，转换为组件对象
//主要功能是进行组件的加载 y230621
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    // 将 ruoyi 后端原有耦合前端的逻辑，迁移到此处
    // 处理 meta 属性
    route.meta = {
      title: route.name,
      icon: route.icon,
      noCache: !route.keepAlive,
    }
    route.hidden = !route.visible
    // 处理 name 属性
    if (route.componentName && route.componentName.length > 0) {
      route.name = route.componentName
    } else {
      // 路由地址转首字母大写驼峰，作为路由名称，适配 keepAlive
      route.name = toCamelCase(route.path, true)
      // 处理三级及以上菜单路由缓存问题，将 path 名字赋值给 name
      if (route.path.indexOf("/") !== -1) {
        const pathArr = route.path.split("/");
        route.name = toCamelCase(pathArr[pathArr.length - 1], true)
      }
    }
    // 处理 component 属性
    if (route.children) { // 父节点
      if (route.parentId === 0) {
        route.component = Layout
      } else {
        route.component = ParentView
      }
    } else { // 根节点
        route.component = loadView(route.component)
    }

    // filterChildren
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
      route.alwaysShow = route.alwaysShow !== undefined ? route.alwaysShow  : true
    } else {
      delete route['children']
      delete route['alwaysShow'] // 如果没有子菜单，就不需要考虑 alwaysShow 字段
    }
    return true
  })
}

//y230620 过滤子路由
//过滤子路由，主要功能是进行路由路径的拼接 y230621
//lastRouter表示上一层路由
function filterChildren(childrenMap, lastRouter = false) {
    let children = [];
    
    childrenMap.forEach((el, index) => {
        
      if (el.children && el.children.length) {
      //y230621
      if (!el.component && !lastRouter) {
      //if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
      if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
    
  return children
}

//y230630 根据传输过来的字符串，来加载对应的组件 import 和require都可以使用。
//在正式环境中可以使用import进行路由懒加载, return () => import(`@/views/${view}`)
//现阶段组件处于/src/components目录下 y230630
export const loadView = (view) => { // 路由懒加载
    //y230601  修改组件加载路径
    //return (resolve) => require([`@/views/${view}`], resolve)
    //y230621
    // return (resolve) => require([`@/components/${view}`], resolve)

    //y230626
    if (view) {
        //y230621
        if (process.env.NODE_ENV === 'development') {
            return (resolve) => require([`@/components/${view}`], resolve)
        } else {
            // 使用 import 实现生产环境的路由懒加载
            return () => import(`@/components/${view}`)
        }
    }

}

export default permission
